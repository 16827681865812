////
/// @group _mixins/util-gutters.scss
////

/// Хелперы отступов - `margin` и `padding`
/// @param {Boolean} $in-rems [true] - значения в `rem'ax`
@mixin util-gutters($in-rems: true) {
	$sides: (t: top, r: right, b: bottom, l: left);
	$property: (m: margin, p: padding);
	$gutter: $gutter;

	@if $in-rems {
		$gutter: rem($gutter);
	}

	@each $prop-name, $prop in $property {
		@each $factor-name, $factor in $gutter-factor {
			._old-#{$prop-name}#{$factor-name} {
				#{$prop}: $gutter*$factor;
			}
		}
		@each $short-side, $side in $sides {
			@each $factor-name, $factor in $gutter-factor {
				._old-#{$prop-name}#{$short-side}#{$factor-name} {
					#{$prop}-#{$side}: $gutter*$factor;
				}
			}
			._old-#{$prop-name}#{$short-side}#{$delimiter}none {
				#{$prop}-#{$side}: 0 !important;
			}
		}
		._old-#{$prop-name}#{$delimiter}none {
			#{$prop}: 0 !important;
		}
	}

	._old-m#{$delimiter}auto {
		margin: auto !important;
	}
	._old-ml#{$delimiter}auto {
		margin-left: auto !important;
	}
	._old-mr#{$delimiter}auto {
		margin-right: auto !important;
	}
}

/// Хелперы отступов - `margin` и `padding` - для медиа запросов
/// @param {Boolean} $in-rems [true] - значения в `rem'ax`
/// @param {Map} $points [$breakpoints] - карта брейкпоинтов
@mixin util-gutters-mq($in-rems: true, $points: $breakpoints) {
	$sides: (t: top, r: right, b: bottom, l: left);
	$property: (m: margin, p: padding);
	$gutter: $gutter;
	@if $in-rems {
		$gutter: rem($gutter);
	}
	@each $point-name, $point in $points {
		@include media($point-name) {
			@each $short-prop, $prop in $property {
				@each $k,
				$point in $gutter-factor {
					._old-#{$point-name}#{$delimiter}#{$short-prop}#{$k} {
						#{$prop}: $gutter*$point;
					}
				}
				@each $short-side, $side in $sides {
					@each $k,
					$point in $gutter-factor {
						._old-#{$point-name}#{$delimiter}#{$short-prop}#{$short-side}#{$k} {
							#{$prop}-#{$side}: $gutter*$point;
						}
					}
					._old-#{$point-name}#{$delimiter}#{$short-prop}#{$short-side}#{$delimiter}none {
						#{$prop}-#{$side}: 0 !important;
					}
				}
				._old-#{$point-name}#{$delimiter}#{$short-prop}#{$delimiter}none {
					#{$prop}: 0 !important;
				}
			}
			._old-#{$point-name}#{$delimiter}m#{$delimiter}auto {
				margin: auto !important;
			}
			._old-#{$point-name}#{$delimiter}ml#{$delimiter}auto {
				margin-left: auto !important;
			}
			._old-#{$point-name}#{$delimiter}mr#{$delimiter}auto {
				margin-right: auto !important;
			}
		}
	}
}
