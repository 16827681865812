
.about-slider {
	display: none;

	@include media($sm) {
		display: block;
	}

	&__pagination {
		display: block;
		position: absolute;
		bottom: rem(30) !important;
		padding: 0 rem(20);
		margin: 0 rem(-15);
		z-index: 100;

		@include media($md) {
			padding: 0 rem(120);
			bottom: rem(60) !important;
		}

		.swiper-pagination-bullet {
			width: rem(80);
			height: rem(6);
			background: #fff;
			opacity: .3;
			border-radius: 0;
			margin: 0 rem(15);
			transition: opacity .25s;

			&:hover {
				opacity: .6;
			}
		}

		.swiper-pagination-bullet-active {
			opacity: 1 !important;
		}
	}

	&__prev, &__next {
		display: none;
		position: absolute;
		cursor: pointer;
		z-index: 100;
		top: 50%;
		width: rem(40);
		height: rem(80);
		transform: translate(0, -50%);
		opacity: .5;
		transition: left .2s, right .2s, opacity .2s;

		@include media($md) {
			display: block;
		}

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			fill: none;
			stroke: #fff;
			stroke-width: 2px;
			transition: stroke .2s;
		}

		&:hover {
			opacity: 1;
		}
	}

	&__prev {
		left: rem(30);

		&:active {
			left: rem(20);
		}
	}

	&__next {
		right: rem(30);

		&:active {
			right: rem(20);
		}
	}
}

.about-slide {
	&__image {
		display: block;
		width: 100%;
	}

	&__content {
		position: absolute;
		left: 20px;
		top: 50px;
		padding: 0 rem(20) rem(80);
		color: #fff;
		font-family: 'ProximaNova', sans-serif;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@include media($md) {
			padding: 0 rem(120) rem(120);
		}
	}
	
	&__title {
		font-weight: 700;
		font-size: rem(42);
		margin: 0 0 rem(15);
		text-shadow: 1px 1px 1px rgba(0,0,0,0.7);
	}
	
	&__caption {
		font-weight: 300;
		font-size: rem(24);
		text-shadow: 1px 1px 1px rgba(0,0,0,0.7);
	}
}

.valuation-content{

}