
.service-block {
	position: relative;
	z-index: 1;
	padding: rem(60) 0;
	background: 50% no-repeat;
	display: flex;
	height: 100%;
	background-size: cover;

	&:after {
		content: '';
		display: block;
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(#000, .35);
		visibility: hidden;
		opacity: 0;
		transition: visibility .2s ease-in, opacity .2s ease-in;
	}

	&:hover {
		&:after {
			visibility: visible;
			opacity: 1;
		}
	}

	&__content {
		z-index: 2;
		position: relative;
		width: 100%;
		max-width: (1680/2)+px;
		box-sizing: border-box;
		padding: 0 rem(20);

		@include media($md) {
			padding: 0 rem(40);
		}
	}

	&__body {
		position: relative;
		color: $color-white;

		@include media($md) {
			padding-left: rem(100);
		}
	}

	&__icon {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		width: rem(70);
		height: rem(70);

		@include media($md) {
			display: block;
		}

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			fill: #fff;
		}
	}

	&__title {
		font-size: rem(30);
		font-weight: 700;
		margin-bottom: rem(5);
		color: $color-white;
		display: inline-block;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

		@include media($def) {
			font-size: rem(38.4);
		}
	}

	&__caption {
		font-size: rem(20);
		font-weight: 300;

		@include media($def) {
			font-size: rem(22.4);
		}
	}

	&__image {
		margin: rem(15) 0 rem(10);
	}

	&--odd {
		@include media($lg) {
			justify-content: flex-end;
		}
	}

	&--even {
		justify-content: flex-start;

		@include media($lg) {
			transform: translate(0, rem(-50));
		}
	}

	&--lg {
		justify-content: center;

		.service-block__content {
			max-width: 1680px;
		}
	}
}

.service-menu {
	position: relative;
	column-count: 1;
	column-gap: rem(20);
	display: inline-block;
	margin: rem(20) 0 0;
	transition: visibility .35s, opacity .35s, left .35s ease-out;

	html.touchevents & {
		visibility: visible !important;
		opacity: 1 !important;
	}

	@include media($sm) {
		column-count: 2;
	}

	@include media($lg) {
		visibility: hidden;
		left: rem(-40);
		opacity: 0;
	}

	&__item {
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		a {
			display: inline-block;
			position: relative;
			padding: rem(5) rem(10);
			color: #fff;
			text-decoration: none;
			font-size: 12px;
			text-transform: uppercase;
			transition: color .2s, background;

			&:before {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 5px;
				right: 5px;
				//height: 0;
				height: 100%;
				//background: #fff;
				z-index: -1;
			}

			&:hover {
				&:before {
					height: 100%;
					left: 0;
					right: 0;
					background: #fff;
					transition: height .2s, background .2s;
				}

				color: #000;
				transition: color .2s;
			}

			@include media($def) {
				font-size: 14px;
			}
		}
	}

	.service-block:hover & {
		visibility: visible;
		opacity: 1;
		left: 0;
	}
}