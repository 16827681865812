////
/// @group _partials/button.scss
////

// scss
// ================================

.button-old {
	display: inline-flex;
	flex-wrap: nowrap;
	align-items: center;
	height: rem(42);
	background: #ffdb4b;
	box-sizing: border-box;
	color: $color-black;
	justify-content: center;
	text-transform: uppercase;
	text-decoration: none;
	font-size: rem(16);
	padding: 0 rem(25);
	user-select: none;
	border: none;
	outline: none !important;
	cursor: pointer;
	transition: transform .2s, border .2s, background .2s, color .2s, box-shadow .2s;

	&:hover,
	&:focus {
		background: #ffce4b;
	}

	&:active {
		background: #ffbd4b;
	}

	i {
		display: block;
		position: relative;
		width: rem(20);
		height: rem(20);
		animation: load .5s infinite;
		animation-play-state: paused;

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-width: 80%;
			max-height: 80%;
			fill: $color-black;
			transition: fill .2s;
		}
	}

	span ~ i {
		margin-left: rem(10);
	}

	&--lg {
		height: rem(54);

		i {
			width: rem(25);
			height: rem(25);
		}
	}

	&--full {
		width: 100%;
	}

	&--hollow {
		background: transparent;
		color: $color-white;
		border: 2px solid #fff;

		&:hover {
			border: 2px solid $color-primary;
			background: $color-primary;
			color: $color-white;
		}
	}

	&--progress {
		i {
			animation-play-state: running;
		}
	}
}

@keyframes load {
	0%   {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
