
.worker-section {
	position: relative;

	@include media($sm) {
		padding: 0 rem(60);
	}

	@include media($def) {
		padding: 0 rem(120);
	}

	&__prev, &__next {
		position: absolute;
		cursor: pointer;
		z-index: 100;
		top: 50%;
		width: rem(40);
		height: rem(80);
		transform: translate(0, -50%);
		opacity: .5;
		transition: left .2s, right .2s, opacity .2s;

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			fill: none;
			stroke: #333333;
			stroke-width: 1px;
			transition: stroke .2s;
		}

		&:hover {
			opacity: 1;
		}
	}

	&__prev {
		left: rem(10);

		&:active {
			left: 0;
		}
	}

	&__next {
		right: rem(10);

		&:active {
			right: 0;
		}
	}
}

.worker-slider {
	margin: rem(60) 0 0;

	.swiper-slide {
		width: 100%;
		align-self: flex-end;

		@include media($md) {
			width: 50%;
		}

		@include media($lg) {
			width: 33.3333%;
		}
	}
}

.worker {
	max-width: 300px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'ProximaNova', sans-serif;

	&__photo {
		margin: 0 0 rem(20);

		img {
			display: block;
			margin: auto;
		}
	}

	&__name {
		font-weight: 700;
		font-size: rem(24);
		color: #000;
		text-align: center;
	}

	&__position {
		color: #787a7d;
		font-size: rem(16);
		margin: 0 0 rem(10);
	}
}