////
/// @group _system/
////

html {
	position: relative;
	height: 100%;
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	line-height: 1.6;
	font-size: 12px;


	@include media(sm) {
		font-size: 13px;
	}

	@include media(md) {
		font-size: 14px;
	}

	@include media(def) {
		font-size: 15px;
	}

	@include media(lg) {
		font-size: 16px;
	}
}

*, // sass-lint:disable-line no-universal-selectors
::after,
::before {
	box-sizing: inherit;
}

body {
	position: relative;
	height: 100%;
	box-sizing: border-box;
	min-width: 320px;
	background-color: #fff;
	overflow-x: hidden;

	.touchevents & {
		cursor: pointer;
	}
}

pre {
	padding: 1.2em;
	tab-size: 4;
	border-width: 0;
	white-space: pre-wrap;
	word-wrap: break-word;
	font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

img {
	max-width: 100%;

	&:not(.lozad) {
		height: auto;
	}
}

small {
	display: inline-block;
	vertical-align: text-bottom;
}

svg {
	button &,
	a & {
		pointer-events: none;
	}
}

audio {
	&[controls] {
		display: block;
		width: 100%;
	}
}

video,
iframe {
	max-width: 100%;
}

hr {
	border-width: 0;
	border-top: 1px solid;
	opacity: .5;
}

::selection {
	text-shadow: none;
	color: #fff;
	background: #2597ff;
}

input,
button,
select,
textarea {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
	-webkit-appearance: none;
	border-radius: 0;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
	-webkit-appearance: none;
}

.roistat-promo-wrap {
	display: none !important; // sass-lint:disable-line no-important
}

.case-fix {
	&.is-hidden {
		transform: translateY(100%);
	}
}

.has-anim {
	.lt-anim {
		opacity: 0;
	}
}
