////
/// @group _partials/view-section.scss
////


// scss
// ================================

.page-wrapper {
	overflow: hidden;
}

.page-section {
	position: relative;
	background: #fff;
	width: 94%;
	margin-left: auto;
	margin-right: auto;

	@include media(def) {
		width: 100%;
	}
}
