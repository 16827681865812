
.service-tech {
	text-align: center;
	margin: rem(60) 0 0;

	&__title {
		font-size: rem(32);
		color: #333333;
		font-weight: 300;
	}

	&__image {
		margin: rem(30) 0 0;
	}
}