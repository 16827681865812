
.advantage {
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 80%;
		height: 10px;
		left: 10%;
		bottom: rem(-30);
		background: linear-gradient(right, rgba(#c9c9c9, .3), #c9c9c9, rgba(#c9c9c9, .3));;
		filter: blur(5px);
		border-radius: 50%;
	}

	&--1 {
		.advantage__circle {
			transform: rotate(135deg);
		}

		.advantage__value {
			transform: rotate(-135deg);
		}

		&:hover {
			.advantage__circle {
				transform: rotate(#{135+180}deg);
			}

			.advantage__value {
				transform: rotate(#{-135-180}deg);
			}
		}
	}

	&--2 {
		.advantage__circle {
			transform: rotate(-135deg);
		}

		.advantage__value {
			transform: rotate(135deg);
		}

		&:hover {
			.advantage__circle {
				transform: rotate(#{-135 + 180}deg);
			}

			.advantage__value {
				transform: rotate(#{135 - 180}deg);
			}
		}
	}

	&--3 {
		.advantage__circle {
			transform: rotate(-45deg);
		}

		.advantage__value {
			transform: rotate(45deg);
		}

		&:hover {
			.advantage__circle {
				transform: rotate(#{-45 + 180}deg);
			}

			.advantage__value {
				transform: rotate(#{45 - 180}deg);
			}
		}
	}

	&--4 {
		.advantage__circle {
			transform: rotate(45deg);
		}

		.advantage__value {
			transform: rotate(-45deg);
		}

		&:hover {
			.advantage__circle {
				transform: rotate(#{45 + 180}deg);
			}

			.advantage__value {
				transform: rotate(#{-45 - 180}deg);
			}
		}
	}

	&__circle {
		width: rem(250);
		height: rem(250);
		margin: 0 auto rem(70);
		position: relative;
		cursor: default;
		transition: transform .35s;
		will-change: transform;
	}

	&__value {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		user-select: none;
		transition: transform .35s;
		will-change: transform;
	}

	&__count {
		font-size: rem(50);
		color: $color-primary;
		line-height: 1.2;
		font-weight: 300;
	}

	&__caption {
		font-size: rem(18);
		color: #9a9c9a;
		max-width: 70%;
		line-height: 1.2;
		font-weight: 300;
	}

}