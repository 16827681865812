////
/// @group _partials/breadcrumbs.scss
////

// scss
// ================================

.breadcrumbs {
	width: 100%;
	display: block;
	position: relative;
	line-height: 0;
	margin: 0 0 rem(45);
	font-size: 14px;
	text-align: center;

	&:after {
		content: '';
		display: block;
		position: absolute;
		width: rem(60);
		height: 3px;
		background: #ffce4b;
		left: 50%;
		margin-left: rem(-30);
		bottom: rem(-15);
	}
}

.breadcrumbs a {
	color: #ffce4b;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.breadcrumbs span {
	display: inline-block;
	position: relative;
	vertical-align: top;
	font-size: 1em;
	line-height: 1.4;
	margin: 0 .8em;

	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		width: 5px;
		height: 2px;
		background: #ffce4b;
		top: calc(50% - 1px);
		left: -1.1em;
	}

	&:before {
		transform-origin: 100% 0;
		transform: rotate(-45deg);
	}

	&:after {
		transform-origin: 100% 100%;
		transform: rotate(45deg);
	}

	&:first-child:before,
	&:first-child:after {
		display: none;
	}
}

.breadcrumbs {
	@include media( $md, max ) {
		display: block;

		span {
			&, &:before {
				display: none;
			}

			&:nth-last-child(2) {
				display: block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 100%;
				background: none;
				margin: 0;

				&:before {
					content: '<';
					margin: 0 5px 0 3px;
					display: none;
				}
			}
		}
	}
}
