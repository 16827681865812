
.partner {
	display: flex;
	align-items: center;
	justify-content: center;

	&__title {
		color: #333333;
		font-size: rem(24);
		text-transform: uppercase;
		font-weight: bold;
		margin: 0 rem(20) 0 0;
	}

	&__image {
		height: rem(70);
		margin: 0 rem(20);
		display: flex;
		align-items: center;
		justify-content: center;
	}
}