
.work-section {
	background: #26272a url("pic/work-bg.jpg") 50% 0 no-repeat;
	padding: rem(80) 0 0 0;
}

.work-card {
	margin: 0 0 rem(80);

	&__icon {
		display: block;
		width: rem(120);
		height: rem(120);
		position: relative;
		background: rgba(#fff, .1);
		border-radius: rem(60);
		margin: 0 0 rem(20);
		transition: background .3s;

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-width: 60%;
			max-height: 60%;
			fill: $color-primary;
			transition: fill .3s;
		}

		.work-card:hover & {
			background: $color-primary;

			svg {
				fill: $color-white;
			}

		}
	}

	&__body {
		margin-left: rem(40);
	}

	&__title {
		display: inline-block;
		text-decoration: none;
		font-size: rem(24);
		color: #fff;
		text-transform: uppercase;
		line-height: 1.2;
		margin: 0 0 rem(20);
		font-weight: 700;
		transition: color .3s;

		&:hover {
			color: $color-primary;
		}
	}

	&__descr {
		font-size: rem(16);
		color: #a0a2a5;
		margin: 0 0 rem(20);
	}
}