
.service-subnav {
	background: #f2f2f2;
	border-bottom: 1px solid #c8c8c8;
	text-align: center;
	padding: rem(16);
	margin: 0 0 rem(40) 0;
	font-size: 0;
	display: none;

	&+.page-section {
		margin-top: rem(40);
	}

	&.is-stuck {
		margin: 0;
		z-index: 200;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 0 rem(16);
		max-width: 100%;
		overflow: auto;
	}

	@include media($def) {
		display: block;
	}

	&__link {
		display: inline-block;
		padding: rem(12) rem(15);
		margin: rem(4) 0;
		color: #787a7d;
		font-size: 12px;
		text-transform: uppercase;
		text-decoration: none;
		transition: background .25s;
		user-select: none;

		@include media($md) {
			font-size: 14px;
		}
		
		&:hover {
			background: #fff;
		}
		
		&--active {
			background: $color-primary;
			color: #fff;

			&:hover {
				background: $color-primary;
			}
		}
	}
}