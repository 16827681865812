
.blog-card {
	position: relative;
	display: flex;
	color: #333333;
	padding: rem(15);
	text-decoration: none;
	height: 100%;

	&:before {
		content: '';
		display: block;
		position: absolute;
		//width: 120%;
		//height: 120%;
		width: 100%;
		height: 100%;
		background: #fff;
		z-index: 1;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: opacity .25s, width .25s, height .25s;
	}

	@include media($def) {
		padding: rem(25);
	}

	@include media($md) {
		display: block;
	}

	&:hover {
		&:before {
			opacity: 1;
			//width: 100%;
			//height: 100%;
		}
	}

	&__image {
		position: relative;
		z-index: 2;
		display: block;
		margin: 0 rem(20) 0 0;
		width: 50%;
		max-width: 50%;
		min-width: 40%;

		img {
			display: block;
			width: 100%;
			//filter: blur(2px);
			transition: filter .25s;

			.blog-card:hover & {
				//filter: none;
			}

			.touchevents & {
				//filter: none;
			}
			@include media($md) {
				position: absolute;
				min-width: 100%;
				min-height: 100%;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				margin:auto;
				width:auto;
			}
		}

		@include media($md) {
			margin: 0 0 1.25rem;
			width: 100%;
			padding-top: 50%;
			position: relative;
			height: 0;
			min-width: 0;
			max-width: 100%;
			overflow: hidden;
		}
	}

	&__body {
		position: relative;
		z-index: 2;
	}
	
	&__title {
		display: block;
		margin: 0 0 rem(20);
		font-size: rem(20);
		font-weight: bold;
		transition: color .25s;

		.blog-card:hover & {
			color: $color-primary;
		}

		@include media($def) {
			font-size: rem(22.4);
		}
	}
	
	&__meta {
		font-size: rem(14);
	}
}