
.popup-wrapper {
	position: relative;
	max-width: 1200px;
	margin: 0 auto;

	&--vacancy {
		max-width: 1400px;
	}
}

.vacancy-popup {
	padding: rem(50) rem(40);
	background: #f2f2f2;
	color: #787a7d;
	font-size: rem(18);
	font-weight: 300;

	@include media($md) {
		padding: rem(80) rem(100);
	}

	&__title {
		color: #333333;
		font-size: rem(40);
		font-weight: 300;
		margin: 0 0 rem(30);
	}

	&__caption {
		font-size: rem(26);
		font-weight: 300;
		margin: 0 0 rem(30);
	}

	ul {
		padding: 0 0 0 rem(20);
		margin: 0;

		&:not(:last-child) {
			margin: 0 0 rem(50);
		}

		li {
			margin: 0 0 rem(15);
		}
	}
}