
.portfolio-infographic {

	img  {
		display: block;
		margin: 0 auto;
	}

	@include media($md) {
		display: block;
	}
}

// portfolio-overview
// -------------------------------------------------------------------------

.portfolio-overview {
	position: relative;
	color: #fff;
	padding: rem(100) 0;
	background-size: cover;
	background-position: 50%;
	margin: rem(-40) 0 0;

	@include media($def, 'max') {
		background-image: none !important;
	}

	@include media($def) {
		padding: 12% 0;
	}

	&__logo {
		margin: 0 0 rem(40);

		img {
			display: block;
		}
	}

	&__title {
		font-size: rem(42);
		font-weight: 700;
		line-height: 1.2;
		margin: 0 0 rem(20);
	}

	&__descr {
		font-size: rem(24);
		font-weight: 300;
		margin: 0 0 rem(25);

		@include media($def) {
			color: rgba(#fff, .6);
		}
	}

	&__service {
		text-decoration: none;
		display: inline-block;
		font-size: 12px;
		color: $color-white;
		text-transform: uppercase;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&:not(:last-child) {
			margin: 0 rem(15) 0 0;
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}

		i {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 20px;
			margin: 0 5px 0 0;

			svg {
				fill: #fff;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				max-width: 80%;
				max-height: 80%;
			}
		}
	}
}

// portfolio-footer
// -------------------------------------------------------------------------

.portfolio-footer {
	display: flex;
	flex-wrap: wrap;
	margin: rem(80) 0 0;
	box-shadow: 0 0 20px rgba(#1d1d1b, .5);

	&__similar {
		width: 50%;
		align-self: stretch;

		@include media($xl) {
			width: 33.33%;
		}
	}

	&__middle {
		width: 100%;
		order: 0;

		@include media($xl) {
			width: 33.33%;
			order: initial;
		}
	}

}

.portfolio-similar {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-size: cover;
	background-position: 50%;
	background-color: #ccc;
	color: #fff;
	text-decoration: none;
	padding: rem(100) 0;
	transition: opacity .25s;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0);
		transition: all 0.3s linear;
	}

	&:hover:after {
		background: rgba(0,0,0,0.8);
	}

	@include media($xl) {
		padding: 0;
	}

	&:hover {
		.portfolio-similar__project {
			opacity: 1;
		}
		.portfolio-similar__title {
			opacity: 1;
		}
	}

	&__title {
		font-size: rem(24);
		font-weight: 300;
		margin: 0 0 rem(10);
		opacity: 0;
		transition: all 0.3s linear;
	}

	&__project {
		font-size: rem(32);
		font-weight: 700;
		opacity: 0;
		transition: all 0.2s linear;
	}
}

.portfolio-social {
	padding: rem(45);
	background: #fff;
	text-align: center;
	font-size: 0;
}

.social-button {
	color: #fff;
	display: inline-flex;
	height: rem(52);
	line-height: rem(52);
	min-width: rem(120);
	text-align: left;
	font-size: rem(16);
	text-decoration: none;
	border-radius: 4px;
	margin: rem(5);

	i {
		display: block;
		position: relative;
		width: rem(52);
		height: rem(52);

		svg {
			position: absolute;
			max-width: 50%;
			max-height: 50%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			fill: #fff;
		}
	}

	&--fb {
		background: map-get($palette-social, facebook);

		&:hover {
			background: darken(map-get($palette-social, facebook), 10%);
		}
	}

	&--pt {
		background: map-get($palette-social, pinterest);

		&:hover {
			background: darken(map-get($palette-social, pinterest), 10%);
		}
	}

	&--tw {
		background: map-get($palette-social, twitter);

		&:hover {
			background: darken(map-get($palette-social, twitter), 10%);
		}
	}

	&--gp {
		background: map-get($palette-social, google-plus);

		&:hover {
			background: darken(map-get($palette-social, google-plus), 10%);
		}
	}
}

.portfolio-link {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: $color-primary;
	padding: rem(45);
	color: #fff;
	font-size: rem(24);
	text-decoration: none;
	font-weight: 300;
	transition: background .25s;

	&:hover {
		background: darken($color-primary, 10%);
	}

	i {
		display: block;
		width: rem(30);
		height: rem(30);
		margin: 0 0 rem(15);

		svg {
			fill: #fff;
			max-width: 100%;
			max-height: 100%;
		}
	}
}

// compare
// -------------------------------------------------------------------------

.compare {
	color: #fff;
	text-align: center;
	overflow: hidden;

	@include media($ms) {
		display: block;
	}

	&__title {
		font-size: rem(32);
		font-weight: 700;
		text-transform: uppercase;
		max-width: 720px;
		margin: 0 auto rem(20);

		&:after {
			content: '';
			display: block;
			margin: rem(15) auto 0;
			width: rem(60);
			height: 2px;
			background: #fff;
		}
	}

	&__caption {
		margin: 0 auto;
		font-size: rem(24);
		font-weight: 300;
		max-width: 720px;
		line-height: 1.4;
	}

	&__container {
		position: relative;
		max-width: 1350px;
		margin: 0 auto;
		user-select: none;

		img {
			display: block;
			margin: 0 auto;
		}
	}

	.compare__container > &__content {
		position: absolute;
		background: rgba($color-primary, .8);
		width: 88.9%;
		height: 87.5%;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
	}
}


// twentytwenty

.twentytwenty-wrapper {
	position: absolute;
	width: 88.9%;
	height: 87.5%;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);

	min-height: 1px;
	min-width: 1px;
}

.twentytwenty-container {
	box-sizing: content-box;
	overflow: hidden;
	position: relative;
	user-select: none;
}

.twentytwenty-before-label,
.twentytwenty-after-label,
.twentytwenty-overlay {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

.twentytwenty-before-label,
.twentytwenty-after-label,
.twentytwenty-overlay {
	transition-duration: 0.5s;
}

.twentytwenty-before-label,
.twentytwenty-after-label {
	transition-property: opacity;
}

.twentytwenty-before-label:before,
.twentytwenty-after-label:before {
	color: white;
	font-size: 50px;
	font-weight: 700;
}

.twentytwenty-before-label:before,
.twentytwenty-after-label:before {
	position: absolute;
	top: 50%;
	margin-top: -0.6em;
	line-height: 1.2;
	padding: 0 20px;
}

.twentytwenty-container img {
	position: absolute;
	top: 0;
	display: block;
}

.twentytwenty-container.active .twentytwenty-overlay,
.twentytwenty-container.active :hover.twentytwenty-overlay {
	background: rgba(0, 0, 0, 0);
}

.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-after-label {
	opacity: 0;
}

.twentytwenty-container.active:hover.twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active:hover.twentytwenty-overlay .twentytwenty-after-label {
	opacity: 0;
}

.twentytwenty-container * {
	box-sizing: content-box;
}

.twentytwenty-before-label {
	opacity: 1;
}

.twentytwenty-after-label {
	opacity: 1;
}

.twentytwenty-overlay {
	transition-property: background;
	background: rgba(0, 0, 0, 0);
	z-index: 25;
}

.twentytwenty-overlay:hover {
	//background: rgba(0, 0, 0, 0.5);
}

.twentytwenty-overlay:hover .twentytwenty-after-label {
	opacity: 1;
}

.twentytwenty-overlay:hover .twentytwenty-before-label {
	opacity: 1;
}

.twentytwenty-before {
	z-index: 20;
}

.twentytwenty-after {
	z-index: 10;
}

.twentytwenty-handle {
	height: 64px;
	width: 64px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 40;
	background: $color-primary;
	cursor: move;
	transform: translate(-50%, -50%);
	transition: background .25s;

	&:hover {
		background: darken($color-primary, 10%);
	}
}

.twentytwenty-handle:before,
.twentytwenty-handle:after {
	content: "";
	display: block;
	position: absolute;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	top: 50%;
	transform: translate(0, -50%);
}

.twentytwenty-handle:before {
	border-right: 7px solid #fff;
	right: 54%;
}

.twentytwenty-handle:after {
	border-left: 7px solid #fff;
	left: 54%;
}