.city {
	display: flex;
	flex-wrap: nowrap;
	color: #333333;

	&__image {
		flex-shrink: 0;
		margin: 0 rem(35) 0 0;
		max-width: 30%;

		img {
			display: block;
			border-radius: 50%;
		}
	}

	&__title {
		font-size: rem(24);
		font-weight: 700;
		text-transform: uppercase;
		margin: 0 0 rem(25);
	}

	&__contact {
		margin-bottom: rem(5);
		font-size: rem(16);
	}

	&__meta {
		font-size: 12px;
	}

	&__link {
		display: inline-block;
		margin-top: rem(20);
		font-size: rem(14);
		text-transform: uppercase;
		font-weight: 700;
		color: $color-primary;
		cursor: pointer;

		i, span {
			display: inline-block;
		}

		i {
			width: rem(22);
			height: rem(22);
			margin: 0 rem(10) 0 0;
			vertical-align: bottom;

			svg {
				max-width: 100%;
				max-height: 100%;
				fill: #000;
			}
		}

		&:hover {
			span {
				text-decoration: underline;
			}
		}
	}
}

.contact-title {
	padding-left: rem(45);
	margin-bottom: rem(12);
	font-size: 14px;
	font-weight: 700;
	color: #333333;
	position: relative;

	i {
		position: absolute;
		left: 0;
		top: rem(-5);
		width: rem(24);
		height: rem(24);

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			fill: #000;
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.contact-section {
	margin: 0 0 rem(20) rem(45);
}

.contact-phone,
.contact-mail {
	position: relative;
	display: inline-block;
	font-size: rem(32);
	color: #000;
	text-decoration: none;
	font-weight: 300;
	line-height: 1.2;

	&:hover {
		color: #ffce4b;
	}
}
