
.cookie-section {
	position: fixed;
	bottom: rem(-100);
	background: rgba(#26272a, .7);
	padding: 0 rem(20);
	width: 96%;
	max-width: 1440px;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 200;
	color: #fff;
	border-radius: 4px;
	visibility: hidden;
	opacity: 0;
	font-size: rem(16);
	text-align: center;
	transition: bottom .4s, visibility .3s, opacity .3s;
	
	a {
		color: #fff;

		&:hover {
			text-decoration: none;
		}
	}

	&--visible {
		visibility: visible;
		opacity: 1;
		bottom: rem(20);
	}
}

.cookie-icon {
	display: inline-block;
	vertical-align: middle;
	width: rem(38);
	height: rem(38);
	position: relative;
	transform: rotateY(-180deg);
	margin: 0 rem(15) 0 0;

	svg {
		fill: #ec1c23;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		max-width: 100%;
		max-height: 100%;
	}
}