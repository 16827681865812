
.vacancy-card {
	background: #fff;
	padding: rem(35) rem(30);
	margin: 0 0 rem(20);
	min-height: rem(370);
	display: flex;
	flex-direction: column;
	color: #333333;
	font-family: 'ProximaNova', sans-serif;
	position: relative;

	&__title {
		margin: 0 0 rem(25);
		font-size: rem(24);
		font-weight: 700;
	}

	&__descr {
		font-size: rem(16);
		flex-grow: 1;
		line-height: 1.4;
	}

	&__link {
		display: inline-block;
		font-size: 14px;
		color: #333333;
		font-weight: 700;
		text-transform: uppercase;
		text-decoration: none;
		outline: none;
		transition: color .25s;

		i, span {
			display: inline-block;
			vertical-align: middle;
		}

		i {
			width: 18px;
			height: 18px;
			margin: 0 0 0 rem(10);
			transition: margin .25s;

			svg {
				max-width: 100%;
				max-height: 100%;
				fill: $color-primary;
			}
		}

		&:hover {
			color: $color-primary;

			i {
				margin: 0 0 0 rem(15);
			}
		}
	}
	.iconVacancy{
		width: 18px;
		height: 18px;
		position: absolute;
		right: 15px;
		top: 15px;
		svg{
			fill: #e01216;
			max-width: 100%;
			max-height: 100%;
			vertical-align: top;
		}
	}
}

.vacancy-send {
	min-height: rem(370);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: rem(35) rem(30);

	&__descr {
		color: #333333;
		font-size: rem(16);
		text-align: center;
		line-height: 1.4;

		a {
			color: $color-primary;
		}
	}

	&__plus {
		position: relative;
		width: rem(54);
		height: rem(54);
		margin: 0 auto;
		transform: translate(-50%, 0);

		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			left: 50%;
			width: rem(54);
			height: rem(14);
			background: #cecfd1;
		}

		&:before {

		}

		&:after {
			transform-origin: 50%;
			transform: rotate(-90deg);
		}
	}
}