////
/// @group _partials/wysiwyg.scss
////


// scss
// ================================

.wysiwyg-old {
	position: relative;
	font-size: rem(16);
	line-height: 1.5;
	color: #333333;
	font-family: ProximaNova,Arial,sans-serif;;
	z-index: 1;

	&.seoText {
		font-size: rem(12.8);
	}

	blockquote, & {
		> :first-child {
			margin-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}
	}

	&--lg {
		font-size: rem(20);

		@include media($def) {
			font-size: rem(22.4);
		}
	}

	&--center {
		text-align: center;
	}
}

// заголовки
.wysiwyg-old {
	h1, h2, h3, h4, h5, h6 {
		clear: both;
		line-height: 1.25;
		font-weight: normal;
		margin: 1.2em 0 .8rem;
		color: #333333;
	}

	h1 {
		font-weight: bold;
		font-size: rem(32);

		@include media($sm) {
			font-size: rem(36);
		}

		@include media($md) {
			font-size: rem(42);
		}
	}

	h2 {
		font-size: rem(32);
	}

	h3 {
		font-size: rem(22.4);
	}

	h4 {
		font-size: rem(22);
	}

	h5 {
		font-size: rem(20);
	}

	h6 {
		font-size: rem(16);
	}
}

// основные элементы
.wysiwyg-old {
	&+.wysiwyg{
		position: relative;
		padding:rem(30) 0 0 0 ;
	}
	p, hr, dl, pre, address, table,
	.table-wrapper, .media-wrapper {
		margin: 0.8em 0;
	}

	blockquote {
		position: relative;
		font-size: rem(24);
		color: #333333;
		font-weight: 300;
		margin: rem(40) 0;
		font-style: italic;
		padding: 0 0 0 rem(90);
		text-align: center;

		&:before {
			content: '“';
			font-family: 'ProximaNova', sans-serif;
			font-weight: 700;
			color: $color-primary;
			font-size: rem(120);
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(0, -30%);
			line-height: 1.0;
		}
	}

	hr {
		clear: both;
		border-width: 0;
		border-top-width: 1px;
	}

	ul, ol {
		font-size: rem(16);
		padding: 0;
		margin: 1.6em 0;
	}

	ol > li {
		list-style-type: none;
		padding: rem(5) 0 0 rem(50);
		margin-bottom: rem(20);
		position: relative;
		counter-increment: step-counter;

		&:before {
			content: counter(step-counter);
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: em(28px);
			height: em(28px);
			background: $color-primary;
			text-align: center;
			line-height: em(28px);
			color: #fff;
			border-radius: 50%;
			font-size: rem(16);
			font-weight: 700;
			user-select: none;
		}
	}

	ul > li {
		position: relative;
		list-style: none;
		padding: rem(5) 0 rem(15) rem(70);
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkU5RDU5QzY0MEUzNDExRTdBNTQwQ0ZDQTg5OTA1ODA3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkU5RDU5QzY1MEUzNDExRTdBNTQwQ0ZDQTg5OTA1ODA3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTlENTlDNjIwRTM0MTFFN0E1NDBDRkNBODk5MDU4MDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTlENTlDNjMwRTM0MTFFN0E1NDBDRkNBODk5MDU4MDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4GE8HZAAABvUlEQVR42ryXv0sDMRTHc/VcLFioCIK6OTiJo4sggjooDnXyv+jYP0AR/AucBREHbbeqKPgfONRRQWhtxR8oopPg+U15gRCSa+4uucCHNLnjPjT3eO8di6KIcf4YC8A2uAbfIHLELmDCIwgZ3wyCEqYTsMbcjwXdZghpoEjb4Bj0HEh/QUN7hY5XHMsZGFGPhV5FJtTn8c0rkrZNUpdi/J4DE0wKpH2T1JUYc41c3QL2inTqXeZxIJZqmPZo+VFg+QxZ+tAPZCmwqp6OuiY57sGUCC6fYq3Ut9go9SmOlcaKsV4FPXAAhrJITQnEJN6Rrh1ZyrXSpOIx0EogN0qVzDULRmPfMfbGLeWxUilzbdA9rYHBZSEfKJXEVXGvVVTHyK2kqcUG+a2tVCdOkqtfwTK4o/U8zY9gCXSSJO+kRUKVP4GVpNJ+65Oi0nD5ItgEl+A5TbkKU5a5T3CYpU7mVY/FGBatHhf/0GI6B/EMzS9ys8cLQtFXzwXK4J1cDbW9bfI86kHMpTeSZ51/tvCGvik19G/gNG20asYkqIAyretBFFXEvymBc4ffSybqondnOXy0fYELsMUdwvcvwAAXWhx4ZFUNLwAAAABJRU5ErkJggg==) 0 0 no-repeat;
		margin-bottom: rem(15);

		//&:not(:last-child):after {
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: rem(80);
			border-bottom: 1px solid #aeaeae;
			bottom: 0;
			left: rem(70);
		}
	}

	&.seoText {
		ul > li {
			list-style: disc;
			color: red;
			background: none;
			color: #333333;
			padding: 0 0 0 0px;
			list-style-position: inside;
			font-size: 0.8rem;
			padding-left: 25px;
		}

		ul > li:after {
			display: none;
		}

		h2 {
			font-size: 1.8rem;
		}
	}

	}

// списки
.wysiwyg-old {
	dd {
		margin-left: 1.5em;
	}

	dt {
		font-weight: bold;
	}

	dd + dt {
		margin: 1em 0 0;
	}
}

// демонстрация кода
.wysiwyg-old {
	pre, code, kbd, samp {
		font-style: normal;
		font-weight: normal;
		font-family: $font-family--monospace;
	}

	pre {
		tab-size: 4;
		padding: 1em 1.5em;
		background-color: #f5f5f5;
	}

	code, kbd, samp {
		padding: 0 .4em;
	}

	code {
		background-color: #f5f5f5;
	}

	kbd {
		background-color: #e1f0fa;
	}

	samp {
		background-color: #f7f8e2;
	}
}

// media элементы
.wysiwyg-old {
	iframe {
		border-width: 0;
	}

	audio {
		width: 100%;
	}

	audio[controls] {
		display: block;
	}

	.media-wrapper {
		&__holder {
			background-color: #f5f5f5;
			position: relative;
			iframe, video {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
	}
}

// таблицы
.wysiwyg-old {
	table  {
		width: 100%;
		background-color: transparent;
		border-collapse: collapse;
		border-spacing: 0;
		border: 1px solid #ddd;
	}

	caption {
		margin-bottom: .5em;
		caption-side: top;
	}

	tbody, tfoot, thead, tr, th, td {
		border: inherit;
	}

	th, td {
	   padding: .5em;
	   text-align: left;
	   vertical-align: top;
	}

	th {
		font-weight: bold;
		text-align: center;
		background-color: #f5f5f5;
	}
}

// таблица "без границ"
.wysiwyg-old .table-null {
	&, th, td {
		border-color: transparent;
	}

	th {
		background: transparent;
	}
}

// таблица "зеброй"
.wysiwyg-old .table-zebra {
	&, th, td {
		border-color: transparent;
	}

	th {
		background: transparent;
	}

	tr:nth-child(odd) {
		background-color: #f5f5f5;
	}
}

// ссылки
.wysiwyg-old {
	a {
		display: inline-block;
		position: relative;
		color: #ec1c23;
		text-decoration: none;
		padding: 0 rem(5);
		transition: color .2s;

		&:before {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 5px;
			right: 5px;
			height: 1px;
			background: #ec1c23;
			transition: height .2s, left .2s .2s, right .2s .2s;
			z-index: -1;
		}

		&:visited {
			color: lighten(#ec1c23, 10%);
		}

		&:hover {
			&:before {
				height: 100%;
				left: 0;
				right: 0;
				transition: height .2s .2s, left .2s, right .2s;
			}

			color: #fff;
			-webkit-transition: all .2s .2s;
			transition: all .2s .2s;
			background: #ec1c23;
			text-decoration: none;
		}

		&:active {
			color: #000;
		}
	}
}

// форматирование и акцентирование
.wysiwyg-old {
	b, strong {
		font-weight: bold;
	}

	i, cite, em, var, address, dfn, caption {
		font-style: italic;
	}

	abbr[title], dfn[title] {
		text-decoration: none;
		border-bottom: 1px dotted;
		cursor: help;
	}
}

// выход элементов за границы
// отступы должны совпадать с отступами .view-size
.wysiwyg-old {
	@include media( $def, max ) {
		> pre {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
}

._clear {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}
