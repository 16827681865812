////
/// @group _partials/view-wrapper.scss
////


// scss
// ================================

.view-wrapper {
	position: relative;
	min-height: 100%;
	overflow: hidden;
}