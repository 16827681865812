
.process-slider {
	position: relative;
	max-width: 1680px;
	width: 100%;
	margin: rem(50) auto rem(40);

	&__prev, &__next {
		position: absolute;
		cursor: pointer;
		z-index: 100;
		top: 50%;
		width: rem(35);
		height: rem(60);
		transform: translate(0, -50%);
		opacity: .5;
		transition: left .2s, right .2s, opacity .2s;

		@include media($def) {
			width: rem(70);
			height: rem(120);
		}

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			fill: none;
			stroke: #333333;
			stroke-width: 1px;
			transition: stroke .2s;
		}

		&:hover {
			opacity: 1;
		}
	}

	&__prev {
		left: rem(10);

		&:active {
			left: 0;
		}
	}

	&__next {
		right: rem(10);

		&:active {
			right: 0;
		}
	}

	&__pagination {
		text-align: center;
		margin-top: rem(35);

		.swiper-pagination-bullet {
			display: inline-block;
			width: 14px;
			height: 14px;
			box-sizing: border-box;
			border-radius: 7px;
			background: transparent;
			border: 2px solid #b0b0b0;
			opacity: 1;
			margin: 0 4px;
			transition: background .25s, border-color .25s;

			&:hover {
				border-color: $color-primary;
			}
		}
		
		.swiper-pagination-bullet-active {
			background: $color-primary;
			border-color: $color-primary;
		}
	}
}

.process-slide {
	background: #f2f2f5;

	&__image {
		img {
			display: block;
		}
	}

	&__caption {
		text-align: center;
		margin: rem(25) 0 0;
		color: #333333;
		font-size: rem(24);
		font-weight: 700;
	}
}