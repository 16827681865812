
.hidden-menu {
	display: block;
	position: fixed;
	height: 100%;
	z-index: 500;
	top: 0;
	box-sizing: content-box;
	transition: width 0.25s ease-in;
	width: 0;
	left: 0;
	right: auto;
	overflow: hidden;

	&.is-active {
		width: 100%;
	}

	&__body {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background: #26272a;
		transition: transform .2s ease;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		top: rem(78);
		width: 100vw;
	}

	&__content {
		position: relative;
		flex-grow: 1;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		box-sizing: border-box;
		flex-shrink: 1;
	}

	&__wrapper {
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		overflow: auto;
		padding: 0 rem(25);

		display: flex;
		flex-direction: column;
		justify-content: center;

	}

	&__footer {
		position: relative;
		z-index: 2;
		overflow: hidden;
		padding-top: rem(25);
		flex-shrink: 0;
		box-shadow: 0 -15px 25px 0 #26272a;
	}
}

.mobile-menu {
	display: inline-block;
	vertical-align: top;

	&__title {
		display: block;
		margin: rem(25) 0;
		text-align: center;
		
		@include media($def) {
			text-align: left;
		}

		a {
			font-size: rem(32);
			color: #fff;
			text-decoration: none;
			font-weight: 700;
			transition: color .2s;
			user-select: none;

			&:hover {
				color: $color-primary;
			}
		}
	}
	
	&__item {
		display: block;
		margin-bottom: rem(10);
		text-align: left;

		@include media($def) {
			text-align: left;
		}
	}

	&__link {
		color: #fff;
		font-size: rem(17.6);
		text-decoration: none;
		transition: color .2s;
		user-select: none;
		vertical-align: middle;
		margin-right: 10px;

		@include media($def) {
			font-size: 16px;
		}

		&:hover {
			color: $color-primary;
		}
	}

	&__button {
		display: inline-block;
		vertical-align: middle;
		width: rem(32);
		height: rem(32);
		background: $color-primary;
		border-radius: 4px;
		cursor: pointer;
		position: relative;
		transform: rotateX(180deg);
		transition: all 0.3s linear 0.2s;
		@include media($def) {
			width: rem(24);
			height: rem(24);
		}

		svg {
			position: absolute;
			max-width: 70%;
			max-height: 70%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			fill: #fff;
			transform: rotateX(0deg);

		}

		&--active {
				transform: rotateX(0deg);
		}
	}

	&__contact {
		font-size: rem(16);
		color: #787a7d;
		margin: 0 0 rem(15);
		line-height: 1.4;
		position: relative;
		text-align: center;

		@include media($def) {
			text-align: left;
			padding-left: rem(35);
		}
	}

	&__icon {
		width: rem(25);
		height: rem(25);
		position: absolute;
		left: 0;
		top: 0;
		display: none;

		@include media($def) {
			display: block;
		}

		svg {
			max-width: 100%;
			max-height: 100%;
			fill: #787a7d;
		}
	}
	
	&__phone, &__mail {
		color: #787a7d;
		text-decoration: none;
		transition: color .25s;

		&:hover {
			color: $color-primary;
		}
	}
	
}

.mobile-submenu {
	padding: rem(10) 0 rem(20) 0;
	margin: 0;
	display: none;

	& > li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__link {
		display: inline-block;
		line-height: 1.4;
		font-size: rem(18);
		transition: color .2s;
		user-select: none;
		color: rgba(#fff, 0.8);
		text-decoration: none;
		padding: rem(5) 0;

		@include media($def) {
			font-size: 14px;
			padding: 0;
		}

		&:hover {
			color: $color-primary;
		}
	}
}