
.scroll-up {
	position: fixed;
	width: rem(60);
	height: rem(60);
	background: transparent;
	right: rem(20);
	bottom: rem(20);
	border-radius: rem(4);
	box-sizing: border-box;
	border: rem(4) solid $color-primary;
	cursor: pointer;
	z-index: 500;
	visibility: hidden;
	opacity: 0;
	transition: visibility .25s, opacity .25s;

	&--visible {
		visibility: visible;
		opacity: 1;
	}

	&:hover {
		background: $color-primary;

		svg {
			fill: #fff;
		}
	}

	svg {
		fill: $color-primary;
		max-width: 60%;
		max-height: 60%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}
}