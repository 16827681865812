////
/// @group _partials/view-size.scss
////


// scss
// ================================

.page-size {
	position: relative;
	max-width: 1440px;
	padding: 0 rem(24);
	margin: 0 auto;
}