.blog-subscriber {
	display: block !important;
	position: fixed;
	bottom: 0;
	opacity: 0;
	left: 50%;
	transform: translateX(-50%) translateY(110%);
	max-width: 1200px;
	width: 96%;
	padding: 1.5rem 1.5rem 2rem;
	margin: 0 auto;
	background-color: #111;
	z-index: 501;
	transition: all 0.5s;

	.form-element {
		margin-bottom: 2rem;
	}

	&.is-visible {
		opacity: 1;
		transform: translateX(-50%) translateY(0);
	}

	&__title {
		color: #fff;
		text-align: center;
		font-size: 1.2rem;
		border-bottom: 2px solid #ffdb4b;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		font-weight: 700;
		padding-right: 15px;

		@include media($md) {
			font-size: 1.5rem;
			text-align: left;
		}
	}

	&__close {
		width: rem(40,16);
		height: rem(40,16);
		line-height: 50px;
		position: absolute;
		right: 0;
		top: 0;
		text-decoration: none;
		text-align: center;
		opacity: .65;
		padding: 0;
		color: #FFF;
		font-style: normal;
		font-size: rem(40,16);
		font-family: Arial,Baskerville,monospace;
		cursor: pointer;
		background: transparent;
		border: 0;
		outline: none;
		box-shadow: none;
		touch-action: manipulation;

		&:hover,
		&:active {
			opacity: 1;
		}

		&:active {
			transform: scale(0.8);
		}
	}

}
