
.blog-container {
	margin: rem(-40) 0 0;
	padding: rem(40) 0;
}

.blog-sidebar {
	position: relative;
	margin-top: rem(40);

	@include media($md) {
		padding-left: 1rem !important;
	}

	@include media($md) {
		margin-top: 0;
	}
}

.blog-content {
	@include media($md) {
		padding-right: 1rem !important;
	}
}

.blog-post {
	padding-bottom: rem(40);
	border-bottom: 1px solid #dddddd;

	&:not(:first-child) {
		margin-top: rem(40);
	}

	&__title {
		font-size: rem(32);
		color: #333333;
		text-decoration: none;
		display: inline-block;
		margin: 0 0 rem(20);
		font-weight: 700;
		transition: color .25s;

		&:hover {
			color: #ffce4b;
		}
	}

	&__image {
		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
		}
	}

	&__meta {
		margin: 0 0 rem(20);
		display: flex;
	}

	&__photo {
		display: block;
		width: rem(46);
		height: rem(46);
		margin: 0 rem(15) 0 0;

		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			border-radius: 50%;
		}
	}

	&__author {
		font-size: 16px;
		color: #ffce4b;
		font-weight: 700;
		margin-bottom: rem(5);
	}

	&__sundry {
		display: flex;
		margin: 0 rem(-15);
		align-items: center;
	}

	&__date {
		color: #bbb;
		font-size: 14px;
		padding: 0 rem(15);
	}

	&__views {
		color: #333;
		font-size: 14px;
		padding: 0 rem(15);
		display: none;

		@include media($ms) {
			display: block;
		}

		i, span {
			display: inline-block;
			vertical-align: middle;
		}

		i {
			position: relative;
			width: rem(24);
			height: rem(24);
			margin: 0 rem(10) 0 0;

			svg {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				max-width: 100%;
				max-height: 100%;
				fill: #666;
			}
		}
	}

	&__comments {
		color: #333;
		padding: 0 rem(15);
		display: none;

		@include media($ms) {
			display: block;
		}

		i, span {
			display: inline-block;
			vertical-align: middle;
		}

		i {
			position: relative;
			width: rem(24);
			height: rem(24);
			margin: 0 rem(10) 0 0;

			svg {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				max-width: 100%;
				max-height: 100%;
				fill: #666;
			}
		}
	}

	&__descr {
		font-size: rem(16);
		color: #333333;
		line-height: 1.5;
		margin: 0 0 rem(20);
	}

	&__link {
		display: inline-block;
		font-size: 14px;
		color: #333333;
		font-weight: 700;
		text-transform: uppercase;
		text-decoration: none;
		transition: color .25s;

		i, span {
			display: inline-block;
			vertical-align: middle;
		}

		i {
			width: 18px;
			height: 18px;
			margin: 0 0 0 rem(10);
			transition: margin .25s;

			svg {
				max-width: 100%;
				max-height: 100%;
				fill: #ffce4b;
			}
		}

		&:hover {
			color: $color-primary;

			i {
				margin: 0 0 0 rem(15);
			}
		}
	}
}

.blog-nav {
	background: #fff;

	&__item {
		display: block;
		font-size: 14px;
		color: #787a7d;
		text-transform: uppercase;
		text-decoration: none;
		padding: rem(10) rem(15);
		transition: background .2s, color .2s;

		&:hover {
			background: #ffce4b;
			color: #000;
		}

		&--active {
			background: #ffce4b;
			color: #000;
		}
	}
}

.last-news {
	position: relative;
	line-height: 1.2;

	&:not(:last-child) {
		margin-bottom: rem(25);
		padding-bottom: rem(25);

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: rem(60);
			height: 1px;
			background: #000;
		}
	}

	&__title {
		display: inline-block;
		color: #000;
		text-transform: uppercase;
		font-weight: 400;
		margin-bottom: rem(5);
		text-decoration: none;
		transition: color 0.2s ease;

		&:hover {
			color: #ffdc4b;
		}
	}

	&__date {
		font-size: 14px;
		color: #999999;
	}
}

.tag {
	display: inline-block;
	height: 32px;
	line-height: 32px;
	background: #dcdcdc;
	padding: 0 20px;
	color: #333333;
	text-transform: uppercase;
	font-size: 12px;
	border-radius: 2px;
	user-select: none;
	margin: 5px 0;
}

.feedback {
	display: flex;
	flex-wrap: nowrap;

	&:not(:last-child) {
		margin-bottom: rem(40);
	}

	&__avatar {
		flex-shrink: 0;
		margin: 0 rem(20) 0 0;

		img {
			display: block;
			border-radius: 50%;
		}
	}

	&__body {
		flex-grow: 1;
	}

	&__header {
		margin: 0 0 rem(15);
	}

	&__name {
		display: inline-block;
		vertical-align: baseline;
		font-size: rem(16);
		color: #333333;
		font-weight: 700;
		margin: 0 rem(20) 0 0;
	}

	&__date {
		display: inline-block;
		vertical-align: baseline;
		color: #999999;
		font-size: rem(14);

		i {
			display: inline-block;
			vertical-align: top;
			width: rem(14);
			height: rem(14);
			margin: 0 rem(5) 0 0;

			svg {
				display: block;
				max-width: 100%;
				max-height: 100%;
				fill: #999999;
			}
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}
	}

	&__msg {
		font-size: rem(16);
		color: #666666;
		line-height: 1.4;
	}
}

.blog-header {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	&__avatar {
		display: block;
		width: 2.875rem;
		height: 2.875rem;
		margin: 0 rem(20) 0 0;
		flex-shrink: 0;

		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			border-radius: 50%;
		}
	}

	&__body {
		flex-grow: 1;
	}

	&__author {
		color: #ffce4b;
		font-size: rem(14);
		margin: 0 0 rem(10);
		font-weight: 700;
	}

	&__info {
		display: flex;
		margin: 0 rem(-13);
	}

	&__meta {
		color: #999999;
		font-size: rem(14);
		margin: 0 rem(13);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		display: flex;
		align-items: center;

		i {
			display: inline-block;
			vertical-align: top;
			width: rem(14);
			height: rem(14);
			margin: 0 rem(5) 0 0;

			svg {
				display: block;
				max-width: 100%;
				max-height: 100%;
				fill: #999999;
			}
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}

		a {
			display: inline-block;
			vertical-align: middle;
			color: #333;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		&:last-child {
			text-decoration: none;

			span {
				color: #000;
				cursor: pointer;
			}

			&:hover {
				span {
					text-decoration: underline;
				}
			}
		}
	}

}

.blog-footer {
	padding: rem(30) 0;
	margin: 0 0 rem(40);
	border-bottom: 1px solid #dddddd;
}

.feedback-list {
	margin: 0 0 rem(40);
	padding: 0 0 rem(60);
	border-bottom: 1px solid #dddddd;
}
