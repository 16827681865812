
.feedback-slider {
	padding: rem(120) 0;
	background: url("pic/feedback.jpg") 50% no-repeat;
	background-size: cover;
	color: #ffffff;

	&__title {
		font-weight: 700;
		text-align: center;
		margin: 0 0 rem(40);
		font-size: rem(28);

		@include media($md) {
			font-size: rem(34);
		}

		@include media($md) {
			font-size: rem(42);
		}
	}

	&__body {

	}

	&__slide {
		text-align: center;
		max-width: 960px;
		margin: 0 auto;

		@include media($ms) {
			padding: 0 rem(100);
		}
	}

	&__msg {
		font-size: rem(24);
		font-weight: 300;
		margin: 0 0 rem(30);
	}

	&__author {
		font-size: rem(16);
		font-weight: 700;
		margin: 0 0 rem(10);
	}

	&__link {
		position: relative;
		font-weight: 300;
		color: $color-primary;
		text-decoration: none;
		display: inline-block;
		margin: 0 0 10px;
		cursor: none;

		&:after {
			content: '';
			position: absolute;
			bottom: -10px;
			width: 100%;
			left: 0;
			border-bottom: 1px solid $color-primary;
			opacity: 0;
			transition: bottom .25s, opacity .25s;
		}

		&:hover {
			&:after {
				bottom: -2px;
				opacity: 1;
			}
		}
	}

	&__next, &__prev {
		display: none;
		position: absolute;
		width: rem(80);
		height: rem(80);
		top: 50%;
		transform: translate(0, -60%);
		cursor: pointer;
		z-index: 100;
		opacity: .7;
		transition: opacity .25s, right .25s, left .25s;

		&:hover {
			opacity: 1;
		}

		@include media($ms) {
			display: block;
		}

		svg {
			max-width: 100%;
			max-height: 100%;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			fill: transparent;
			stroke: #fff;
			stroke-width: 2px;
		}
	}

	&__next {
		right: 0;

		&:active {
			right: rem(-5);
		}
	}

	&__prev {
		left: 0;

		&:active {
			left: rem(-5);
		}
	}

	&__pagination {
		margin: 0 0 rem(30);
		text-align: center;

		span {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background: transparent;
			border: 2px solid #fff;
			opacity: 1;
			transition: background .25s, border .25s;

			&:hover {
				border: 2px solid $color-primary;
			}

			&.swiper-pagination-bullet-active {
				background: $color-primary;
				border: 2px solid $color-primary;
			}
		}
	}
}