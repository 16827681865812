////
/// @group _partials/pagination.scss
////

// scss
// ================================

.pagination {
	text-align: center;
	margin: rem(60) 0;

	&__link {
		display: inline-block;
		vertical-align: middle;
		color: #787a7d;
		font-size: rem(20);
		width: rem(40);
		height: rem(40);
		line-height: rem(40);
		text-align: center;
		text-decoration: none;
		border-radius: rem(4);
		margin: 0 2px;
		transition: background .2s, color .2s;

		&--active {
			background: #ffce4b;
			color: #000;
		}

		&:hover {
			background: #ffce4b;
			color: #000;
		}
	}

	&__prev, &__next {
		display: inline-block;
		position: relative;
		vertical-align: middle;
		width: rem(40);
		height: rem(40);
		border-radius: rem(4);
		transition: background .2s;

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			fill: #ffce4b;
			max-width: 50%;
			max-height: 50%;
			transition: fill .2s;
		}

		&:hover {
			background: #ffce4b;

			svg {
				fill: #000;
			}
		}
	}

	&__prev {
		margin-right: rem(10);
	}

	&__next {
		margin-left: rem(10);
	}
}
