////
/// @group _partials/title.scss
////


// scss
// ================================

.title-old {
	line-height: 1.2;
	font-weight: bold;
	color: #333333;
	font-size: rem(42);
	margin: 1.2em 0 .8em;
	font-family: Coco Gothic, Arial;

	&:first-child {
		margin-top: 0;
	}

	&--center {
		text-align: center;
	}

	&--caps {
		text-transform: uppercase;
	}

	&--decor {
		position: relative;
		padding: 0 0 rem(14);

		&:after {
			content: '';
			display: block;
			position: absolute;
			width: rem(60);
			height: 2px;
			background: #000;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}

	&--white {
		color: #fff;
	}

	&--lg {
		font-size: rem(28);

		@include media($md) {
			font-size: rem(34);
		}

		@include media($md) {
			font-size: rem(42);
		}
	}

	&--md {
		font-size: rem(22);

		@include media($md) {
			font-size: rem(26);
		}
	}

	&--sm {
		font-size: rem(20);

		&.title-old--decor {
			&:after {
				background: #000;
				left: 0;
				transform: translate(0, 0);
			}
		}
	}
}

