////
/// @group _partials/view-footer.scss
////


// scss
// ================================

.page-footer {
	position: relative;
	background: #26272a;

	&__above {
		display: none;
		border-bottom: 1px solid #2f3033;
		padding: rem(40) 0;

		@include media($def) {
			display: block;
		}
	}

	&__below {
		padding: rem(40) 0;
	}
}

.footer-column {
	position: relative;
	padding-left: rem(45);
	overflow: hidden;

	&__icon {
		position: absolute;
		left: 0;
		top: 0;
		width: rem(30);
		height: rem(30);

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			fill: $color-primary;
			max-width: 100%;
			max-height: 100%;
		}
	}

	&__address {
		color: #ffffff;
		font-size: rem(14);
		margin-bottom: rem(10);
		line-height: 1.4;

		span {
			display: block;
		}
	}

	&__index {
		color: #787a7d;
		font-size: rem(14);
		line-height: 1.4;
	}

	&__title {
		color: #787a7d;
		font-size: rem(14);
		padding: rem(10) 0;
		margin: 0 0 rem(10);
		font-weight: bold;
	}

	&__phone,
	&__email {
		position: relative;
		display: inline-block;
		font-size: rem(28.8);
		color: #fff;
		text-decoration: none;
		font-weight: 300;

		&:after {
			content: '';
			display: block;
			position: absolute;
			height: 1px;
			bottom: 0;
			width: 100%;
			background: #fff;
			opacity: 0;
			transition: opacity .4s;
		}

		&:not(:last-child) {
			margin-bottom: rem(5);
		}

		&:hover {
			&:after {
				opacity: 1;
			}
		}
	}
}

.footer-social {
	margin-bottom: rem(25);
	text-align: center;

	&__title {
		color: #fff;
		font-size: rem(22.4);
		margin: rem(15) 0;
	}

	&__button {
		position: relative;
		display: inline-block;
		width: rem(64);
		height: rem(64);
		border: 1px solid rgba(#fff, .5);
		border-radius: 50%;
		margin: rem(5) rem(10);
		transition: background .25s, border .25s;

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-width: 50%;
			max-height: 50%;
			fill: rgba(#fff, .5);
			transition: fill .25s;
		}

		&--facebook {
			&:hover {
				border: 1px solid map-get($palette-social, facebook);
				background: map-get($palette-social, facebook);

				svg {
					fill: #fff;
				}
			}
		}

		&--youtube {
			&:hover {
				border: 1px solid map-get($palette-social, youtube);
				background: map-get($palette-social, youtube);

				svg {
					fill: #fff;
				}
			}
		}

		&--twitter {
			&:hover {
				border: 1px solid map-get($palette-social, twitter);
				background: map-get($palette-social, twitter);

				svg {
					fill: #fff;
				}
			}
		}

		&--google {
			&:hover {
				border: 1px solid map-get($palette-social, google-plus);
				background: map-get($palette-social, google-plus);

				svg {
					fill: #fff;
				}
			}
		}

		&--linkedin {
			&:hover {
				border: 1px solid map-get($palette-social, linked-in);
				background: map-get($palette-social, linked-in);

				svg {
					fill: #fff;
				}
			}
		}

		&--pinterest {
			&:hover {
				border: 1px solid map-get($palette-social, pinterest);
				background: map-get($palette-social, pinterest);

				svg {
					fill: #fff;
				}
			}
		}

		&--instagram {
			&:hover {
				border: 1px solid map-get($palette-social, instagram);
				background: map-get($palette-social, instagram);

				svg {
					fill: #fff;
				}
			}
		}
	}
}

.copyright {
	color: #6f6f71;
	font-size: rem(12.8);
	text-align: center;
}

.map-wrapper {
	position: relative;
	height: 350px;
	background: #252525;
	margin-bottom: 3rem;

	&__button {
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 0);
		white-space: nowrap;

		&:active {
			transform: translate(-50%, 2px) !important;
		}
	}
}
