
.certificate {
	display: block;
	position: relative;
	max-width: 320px;
	width: 100%;
	margin: 0 auto rem(35);
	outline: none;

	img {
		display: block;
		width: 100%;
	}

	&__overlay {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		overflow: hidden;
		bottom: 0;
		width: 100%;
		left: 0;
		height: rem(6);
		background: rgba($color-primary, .85);
		transition: height .25s .25s;

		.certificate:hover & {
			height: 100%;
			transition: height .25s;
		}
	}

	&__icon {
		display: block;
		width: rem(70);
		height: rem(70);
		margin: 0 0 rem(20);
		opacity: 0;
		transform: translate(0, rem(-30));
		transition: transform .25s, opacity .25s;

		svg {
			fill: #fff;
			max-width: 100%;
			max-height: 100%;
		}

		.certificate:hover & {
			opacity: 1;
			transform: translate(0, 0);
			transition: transform .25s .25s, opacity .25s .25s;
		}
	}

	&__caption {
		font-size: rem(24);
		color: #fff;
		font-weight: 300;
		text-align: center;
		padding: 0 rem(30);
		line-height: 1.4;
		opacity: 0;
		transform: translate(0, rem(-30));
		transition: transform .25s, opacity .25s;

		.certificate:hover & {
			opacity: 1;
			transform: translate(0, 0);
			transition: transform .25s .25s, opacity .25s .25s;
		}
	}
}