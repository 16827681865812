
.project-card {
	display: block;
	position: relative;
	background: #ccc 50% 50%;
	padding: 75% 0 0;
	box-sizing: border-box;
	background-size: cover;
	text-decoration: none;

	&__content {
		position: absolute;
		display: flex;
		justify-content: center;
		background: rgba($color-primary, .8);
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		visibility: hidden;
		opacity: 0;
		transition: visibility .25s ease-in .2s, opacity .25s ease-in .2s;

		.project-card:hover & {
			transition: visibility .25s ease-in, opacity .25s ease-in;
			visibility: visible;
			opacity: 1;
		}

		.touchevents & {
			visibility: visible;
			opacity: 1;
			top: auto;
			padding: rem(30) rem(100) rem(30) 0;
			background-color: rgba(#000, .5);
			background-image: url(pic/right-arrow.svg);
			background-position: calc(100% - 18px) 50%;
			background-repeat: no-repeat;
			background-size: rem(60);

			@include media($sm) {
				padding: rem(30) 0;
				background-image: none;
			}
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	&__title {
		font-size: rem(26);
		color: $color-white;
		font-weight: bold;
		margin-bottom: 0;
		max-width: 75%;
		text-align: center;
		visibility: hidden;
		opacity: 0;
		transform: translate(0, rem(-50));
		transition: transform .25s ease, visibility .25s ease, opacity .25s ease;

		.project-card:hover & {
			visibility: visible;
			opacity: 1;
			transform: translate(0, 0);
			transition: transform .25s ease .2s, visibility .25s ease .2s, opacity .25s ease .2s;
		}

		@include media($md) {
			font-size: rem(18);
		}

		@include media($def) {
			font-size: rem(20);
			margin-bottom: rem(20);
			max-width: 60%;
		}

		@include media($lg) {
			font-size: rem(26);
		}

		.touchevents & {
			visibility: visible;
			opacity: 1;
			transform: translate(0, 0);
			margin-bottom: 0;
		}
	}

	&__meta {
		display: none;
		max-width: 80%;
		text-align: center;
		visibility: hidden;
		opacity: 0;
		transform: translate(0, rem(50));
		transition: transform .25s ease, visibility .25s ease, opacity .25s ease;

		.project-card:hover & {
			visibility: visible;
			opacity: 1;
			transform: translate(0, 0);
			transition: transform .25s ease .2s, visibility .25s ease .2s, opacity .25s ease .2s;
		}

		@include media($def) {
			display: block;
		}

		.touchevents & {
			display: none !important;
		}
	}

	&__service {
		display: inline-block;
		font-size: 12px;
		color: $color-white;
		text-transform: uppercase;
		margin: 0 rem(10);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		span {
			display: inline-block;
			vertical-align: middle;
		}

		i {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 20px;

			svg {
				fill: #fff;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				max-width: 80%;
				max-height: 80%;
			}
		}
	}
}