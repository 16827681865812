////
/// @group _partials/view-header.scss
////

// scss
// ================================

.page-header {
	position: fixed;
	z-index: 505;
	width: 100%;
	top: 0;
	background: $color-white;
	box-shadow: 0 0 2px #999;
	padding: 2px 0;
	@include media($ms){
		padding: 0;
	}

	&__size {
		max-width: 1680px;
		margin: 0 auto;
		padding: 0 rem(15);
	}
}

// logo

.wezom-logo {
	display: flex;
	align-items: center;
	text-decoration: none;
	user-select: none;

	&__cube {
		display: block;
		position: relative;
		width: rem(42px);
		height: rem(42px);
		margin: 0 rem(10) 0 rem(5);
		transform-style: preserve-3d;
		transition: transform .3s ease-out;

		.no-ie .wezom-logo:hover & {
			transform: rotateX(90deg);
		}
		.no-touchevents .wezom-logo:hover & {
			-webkit-transform: rotateX(90deg);
			transform: rotateX(90deg);
		}
		@include media($ms){
			width: rem(78px);
			height: rem(78px);
		}
	}

	&__icon {
		display: block;
		position: absolute;
		width: rem(42px);
		height: rem(42px);
		@include media($ms){
			width: rem(78px);
			height: rem(78px);
		}
		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-width: 70%;
			max-height: 70%;
		}

		&--front {
			transform: translateZ(rem(39));
			background: #ec1c23;

			svg {
				fill: #fff;
			}
		}

		&--back {
			transform: rotateX(-90deg) translateY(rem(39));
			transform-origin: bottom center;
			background: #fff;
			box-shadow: inset 0 0 0 2px #ec1c23;

			svg {
				fill: #ec1c23;
			}
		}
	}

	&__title {
		display: flex;
		position: relative;
		height: rem(42px);
		width: rem(48px);
		justify-content: center;
		align-items: center;
		svg {
			max-height: 30%;
			fill: #1d1d1b;
			transition: fill .25s;
		}
		@include media($ms){
			height: rem(78px);
			width: rem(120px);
		}
	}

	&__caption {
		display: none;
		flex-direction: column;
		line-height: 1.4;
		color: #1d1d1b;
		transition: color .25s;
		font-size: rem(12.8);
		font-family: 'ProximaNova', Arial, sans-serif;
		font-weight: 700;

		.wezom-logo:hover & {
			//color: #ec1c23;
		}

		@include media($md) {
			display: flex;
		}
	}
}

// menu

.header-menu {
	display: flex;

	&__link {
		display: block;
		position: relative;
		margin: 0 rem(15);
		text-transform: uppercase;
		text-decoration: none;
		color: #1d1d1b;
		font-weight: 700;
		font-size: rem(16);
		padding: rem(10) 0;
		transition: color 0.3s;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			left: auto;
			width: 0;
			height: 3px;
			background: #ec1c23;
			transition: width 0.3s;
		}

		&:hover {
			color: #ec1c23;

			&::before {
				left: 0;
				right: auto;
				width: 100%;
			}
		}

		&--active {
			color: #ec1c23;

			&::before {
				left: 0;
				right: auto;
				width: 100%;
			}
		}
	}
}

// hamburger

.hamburger {
	padding:2px 0;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;

	@include media($ms){
		padding: 15px 15px;
	}
}

.hamburger:hover {
	.hamburger-inner {
		&::after {
			width: 100%;
			left: 0;
			right: auto;
		}
	}

	.hamburger-inner__top,
	.hamburger-inner__bottom {
		&::after {
			width: 100%;
			left: 0;
			right: auto;
		}
	}
}

.hamburger-box {
	width: 40px;
	height: 24px;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;

	&::after {
		left: auto;
		right: 0;
	}
}

.hamburger-inner,
.hamburger-inner__top,
.hamburger-inner__bottom {
	width: 40px;
	height: 4px;
	border-radius: 2px;
	background-color: #000;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;

	&::after {
		content: '';
		display: block;
		position: absolute;
		height: 100%;
		width: 0;
		background: $color-primary;
		border-radius: 2px;
	}
}

.hamburger-inner__top {
	&::after {
		transition: width .35s ease-out;
	}
}

.hamburger-inner {
	&::after {
		transition: width .35s ease-out .06s;
	}
}

.hamburger-inner__bottom {
	&::after {
		transition: width .35s ease-out 0.12s;
	}
}

.hamburger-inner__top,
.hamburger-inner__bottom {
	display: block;

	&::after {
		left: auto;
		right: 0;
	}
}

.hamburger-inner__top {
	top: -10px;
}

.hamburger-inner__bottom {
	bottom: -10px;
}

.hamburger--spin .hamburger-inner {
	transition-duration: 0.3s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner__top {
	transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner__bottom {
	transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
	transform: rotate(225deg);
	transition-delay: 0.14s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner__top {
	top: 0;
	opacity: 0;
	transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
}

.hamburger--spin.is-active .hamburger-inner__bottom {
	bottom: 0;
	transform: rotate(-90deg);
	transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.d-roistat-promo {
	position: absolute;
	left: 0;
	bottom: -13px;
	font-family: Arial,sans-serif;
	color: #000;
	font-size: 14px;
	white-space: nowrap;
	background-color: #fff;
	padding: 3px 15px 3px 3px;
	line-height: 1;
	width: 100%;
	text-align: right;
	z-index: -1;

	@include media(361px,min) {
		bottom: 0;
		padding-right: 19px;
	}
	@include media(769px,min) {
		bottom: 2px;
		background-color: transparent;
		right: 113px;
		left: auto;
		width: auto;
		padding: 0;
	}
	@include media($lg) {
		right: 125px !important;
		bottom: 3px !important;
	}
}