
.service-preview {
	margin-bottom: rem(50);

	&__icon {
		position: relative;
		width: rem(120);
		height: rem(120);
		border-radius: rem(60);
		background: #fff2f2;
		margin-bottom: rem(20);
		transition: background .3s;

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-width: 60%;
			max-height: 60%;
			fill: $color-primary;
			transition: fill .3s;

			.service-preview:hover & {
				fill: $color-white;
			}
		}

		.service-preview:hover & {
			background: $color-primary
		}
	}

	&__body {
		padding-left: rem(35);
		line-height: 1.5;
	}

	&__title {
		font-size: rem(24);
		color: #333333;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: rem(16);
		transition: color .3s;
		text-decoration: none;

		&:hover {
			color: $color-primary
		}
	}

	&__descr {
		font-size: rem(16);
		font-family: 'ProximaNova', sans-serif;

		a {
			color: #787a7d;
			text-decoration: none;

			&:hover {
				color: $color-primary;
				text-decoration: underline;
			}
		}
	}
}
