
.page-head {
	position: relative;
	height: rem(500);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-size: cover;
	background-position: 50%;
	color: #fff;
	line-height: 1.2;

	&+.page-section {
		margin-top: 2.5rem;
	}

	&--simple {
		color: #333333;
		height: auto;
		padding: rem(32) 0;
		border-bottom: 1px solid #ddd;
	}

	&__title {
		font-size: rem(30);
		font-family: Coco Gothic, Arial;
		font-weight: 700;
		max-width: 940px;
		text-align: center;
		margin: 0 0 0.2em;
		text-transform: uppercase;
		padding: 0 rem(15);

		@include media($sm) {
			font-size: rem(35);
		}

		@include media($md) {
			font-size: rem(35);
		}

		@include media($lg) {
			font-size: rem(55.8);
		}

		&--blog {
			font-size: rem(30);
			text-transform: none;
			max-width: 1400px;

			@include media($sm) {
				font-size: rem(35);
			}

			@include media($md) {
				font-size: rem(40);
			}

			@include media($lg) {
				font-size: rem(56);
			}
		}
	}

	&__caption {
		font-size: rem(20);
		max-width: 940px;
		text-align: center;
		padding: 0 rem(15);
		font-family: 'ProximaNova', sans-serif;
		font-weight: 300;

		@include media($sm) {
			font-size: rem(24);
		}

		@include media($md) {
			font-size: rem(26);
		}
	}

	&__prev, &__next {
		display: flex;
		text-decoration: none;
		color: rgba(#333333, 0.25);
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		align-items: center;
		flex-basis: rem(160);
		max-width: rem(160);
		font-size: rem(24);
		font-family: 'ProximaNova', sans-serif;
		font-weight: 300;
		transition: color .25s;

		@include media($def) {
			display: flex;
		}

		&:hover {
			color: rgba(#333333, 0.6);

			i svg {
				fill: rgba(#333333, 0.6);
			}
		}

		i {
			display: block;
			width: rem(40);
			height: rem(60);
			min-width: rem(40);

			svg {
				max-width: 100%;
				max-height: 100%;
				fill: rgba(#333333, 0.25);
				transition: fill .25s;
			}
		}

		span {
			display: block;
			text-align: center;
		}
	}

	&__prev {
		left: rem(30);
		flex-direction: row;

		i {
			margin: 0 rem(15) 0 0;
		}
	}

	&__next {
		right: rem(30);
		flex-direction: row-reverse;

		i {
			margin: 0 0 0 rem(15);
		}
	}
}

.below-head-image {
	margin: rem(-40) 0 rem(40) 0;
	user-select: none;
	display: none;

	@include media($md) {
		display: block;
	}

	img {
		display: block;
		margin: 0 auto;
	}
}
