.hidden-menu__wrapper .grid > .gcell {
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .portfolio-overview:after {

    background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.35s ease 0s;
    z-index: 1;

  }
  .portfolio-overview > * {
    z-index: 2;
    position: relative;
  }
}

@media only screen and (max-width: 479px) {
  .footer-social__button {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0.3125rem 0.1rem;
  }
}

.wezom-logo__caption span.mobi {
  display: none;
}

@media only screen and (max-width: 768px) {
  .wezom-logo__caption {
    display: flex;
  }
}

.wezom-logo__caption span.mobi {
  display: none;
}

.resp_on {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .hidden-menu__body {
    top: 80px;
  }
  .hamburger {
    padding: 0;
    margin-right: 20px;
  }
  .resp_off {
    display: none !important;
  }
  .resp_on {
    display: inline-block !important;
  }
  .resp_on img {
    vertical-align: top;
  }
  .wezom-logo__icon--back {
    transform: rotateX(-90deg) translateY(40px)
  }

  .wezom-logo__cube {
    margin-left: 0;
    height: 80px;
    width: 80px;
  }
  .wezom-logo__icon {
    width: 100%;
    height: 100%;
  }
  .page-header__size {
    padding: 0;
  }
  .wezom-logo__title {
    display: none;
  }
  .wezom-logo__caption {
    display: flex;
    line-height: 1.1;
  }
  .wezom-logo__caption span.mobi {
    display: inline;
  }
  .page-header {
    padding: 0;
  }
}

@media screen and (max-width: 1400px) {

  .portfolio-similar {
    order: 1 !important;
  }
  .portfolio-footer__middle {
    order: 2 !important;
  }
  .portfolio-similar__title {
    text-align: center;
  }
  .portfolio-similar__project {
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .hidden-menu__wrapper {
    display: block;
  }

  .main-slider__slide {
    background-color: rgba(0, 0, 0, 0.45);
  }

}

@media screen and (max-width: 768px) {
  .hidden-menu__body {
    top: 80px;
  }
  .blog-card__image {
    margin: 0 1.25rem 0 0;
    width: 100px;
    padding-top: 0;
    position: relative;
    height: auto;
    min-width: 100px;
    max-width: 100px;
    overflow: hidden;
  }
  .blog-card__image img {
    position: absolute;
    min-width: 0%;
    min-height: 0%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

@media screen and (max-width: 720px) {
  .page-head__prev span, .page-head__next span {
    display: none;
  }
  .page-head__next {
    right: 0;
  }
  .page-head__prev {
    left: 0;
  }
}

@media only screen and (max-width: 360px) {
  .wezom-logo__cube {
    height: 55px;
    width: 55px;
    margin-right: 3px;
  }
  .page-header .grid--space-def > .gcell {
    padding: 0 5px;
  }
  .hidden-menu__body {
    top: 55px;
  }
  .wezom-logo__icon--back {
    transform: rotateX(-90deg) translateY(27px)
  }

}

@media only screen and (max-height: 460px) {
  .hidden-menu__body {
    top: 55px;
  }
  .wezom-logo__cube {
    height: 55px;
    width: 55px;
    margin-right: 3px;
  }
  .page-header .grid--space-def > .gcell {
    padding: 0 5px;
  }

}

.pageErrorWrap {
  color: #fff;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url(../css/pic/allcrafted_grain2.png) 0 0 repeat, url(../css/pic/bg_404.jpg) 50% 50%/cover no-repeat;
}

.pageErrorConteiner {
  padding: 5% 20px;
  max-width: 800px;
  position: relative;
  text-align: center;
  top: 40%;
  margin: auto;
  transform: translateY(-50%);
}

.pageErrorLogo {
  display: inline-block;
  margin-top: 100px;
  margin-bottom: 100px;
}

.pageErrorTitle {
  position: relative;
  letter-spacing: -1px;
  line-height: 60px;
  font-size: 60px;
  font-weight: 100;
  margin: 0 0 50px;
  color: rgba(255, 255, 255, 0.2);
}

.pageErrorSubtitle {
  font: 24px/1.2em Arial, Helvetica, sans-serif;
  color: #fff;
  margin-bottom: 40px;
}

.pageErrorContent {
  text-transform: uppercase;
}

.pageErrorConteiner .wysiwyg,
.pageErrorConteiner .wysiwyg a {
  color: #fff;
}

.pageErrorConteiner .wysiwyg a:hover {
  text-decoration: none;
}


.roistat-promo-wrap {
	display: none !important;
}
