
// main-slider

.main-slider {
	margin: 0 0 rem(50);
	position: relative;

	.swiper-slide {
		background-position: 50%;
		background-size: cover;
	}

	&__slide {
		position: relative;
		width: 100%;
		height: auto;
		padding: 150% 0 0;
		box-sizing: border-box;
		background-color: rgba(#000, .0);
		//background: rgba(#000, .25) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACVJREFUeNpiZGBg+M8AAYz//0OY/5EEGRgZEAAsyARTBgMAAQYAfpQJ/AdiO/UAAAAASUVORK5CYII=) repeat;
		//background: rgba(#000, .25) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABhJREFUeNpi+P//PwMUwBlgDiOyCECAAQCgkwX81EUsmwAAAABJRU5ErkJggg==) repeat;
		//background: rgba(#000, .25) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAD5JREFUeNpiZmBg+AvEH4D4JAMC5IGIfCB+DcR2UEE7KB8M7KGcXCjtwICm/S/MGAY07TBjQSZgaAcbCxBgALwuEAifDhFXAAAAAElFTkSuQmCC) repeat;

		@include media($sm) {
			padding: 50% 0 0;
			min-height: 340px;
		}

		@include media($xl) {
			background-color: transparent;
		}
	}

	&__content {
		position: absolute;
		z-index: 2;
		left: 50%;
		top: 50%;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: rem(78) rem(128) 0;
		box-sizing: border-box;
		text-align: center;
		transform: translate(-50%, -50%);

		@include media($def) {
			text-align: left;
		}
	}

	&__title {
		font-size: rem(30);
		color: #fff;
		font-weight: 700;
		margin: 0 0 rem(20);
		text-shadow: 1px 1px 2px rgba(0,0,0,.5);
		text-transform: uppercase;
		line-height: 1.0;

		@include media($sm) {
			font-size: rem(40);
		}

		@include media($md) {
			font-size: rem(50);
		}

		@include media($lg) {
			font-size: rem(57.6);
		}
	}

	&__caption {
		font-size: rem(20);
		color: #fff;
		font-weight: 300;
		text-shadow: 1px 1px 2px rgba(0,0,0,.5);

		@include media($sm) {
			font-size: rem(25);
		}

		@include media($md) {
			font-size: rem(28.8);
		}
	}

	&__prev, &__next {
		position: absolute;
		cursor: pointer;
		z-index: 100;
		top: 50%;
		width: rem(70);
		height: rem(140);
		transform: translate(0, -50%);
		margin-top: rem(78/2);
		opacity: .5;
		display: none;
		transition: left .2s, right .2s, opacity .2s;

		@include media($sm) {
			display: block;
		}

		@include media($def) {
			width: rem(140);
			height: rem(280);
		}

		svg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			fill: none;
			stroke: #fff;
			stroke-width: 1px;
			transition: stroke .2s;
		}

		&:hover {
			opacity: 1;
		}
	}

	&__prev {
		left: 0;

		&:active {
			left: rem(-10);
		}
	}

	&__next {
		right: 0;

		&:active {
			right: rem(-10);
		}
	}
}

.button-down {
	display: none;
	position: absolute;
	width: 30px;
	height: 45px;
	box-sizing: border-box;
	border: 2px solid $color-white;
	z-index: 50;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, -75%);
	border-radius: 15px;
	cursor: pointer;

	@include media($def) {
		display: block;
	}

	&__arrow {
		position: absolute;
		width: 16px;
		height: 4px;
		opacity: 0;
		left: 50%;
		margin: 0 0 0 -8px;
		transform: scale3d(0.5, 0.5, 0.5);
		animation: move 3s ease-out infinite;

		&:first-child {
			animation: move 3s ease-out 1s infinite;
		}

		&:nth-child(2) {
			animation: move 3s ease-out 2s infinite;
		}

		&:before,
		&:after {
			content: ' ';
			position: absolute;
			top: 0;
			height: 100%;
			width: 51%;
			background: #fff;
		}

		&:before {
			left: 0;
			transform: skew(0deg, 30deg);
		}

		&:after {
			right: 0;
			width: 50%;
			transform: skew(0deg, -30deg);
		}
	}
}

@keyframes move {
	25% {
		opacity: 1;

	}
	33% {
		opacity: 1;
		transform: translateY(10px);
	}
	67% {
		opacity: 1;
		transform: translateY(25px);
	}
	100% {
		opacity: 0;
		transform: translateY(40px) scale3d(0.5, 0.5, 0.5);
	}
}

@keyframes pulse {
	to {
		opacity: 1;
	}
}